import React, { useEffect, useRef, useState } from 'react';
import Carousel from "react-elastic-carousel";
import { useData } from '../Context/Context_Provider';

function Banner() {
    const { imag_url, getHomeBanner, homeBanner } = useData();
    const carouselRef = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [timeoutId, setTimeoutId] = useState(null);

    useEffect(() => {
        getHomeBanner();
    }, []);

    useEffect(() => {
        // Clear the timeout when the component unmounts
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [timeoutId]);

    const breakPoint = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 1 },
        { width: 768, itemsToShow: 1 },
        { width: 1100, itemsToShow: 1 },
    ];

    const handleSlideChange = (currentItem, pageIndex) => {
        setCurrentIndex(pageIndex);

        // Clear existing timeout if there is one
        if (timeoutId) {
            clearTimeout(timeoutId);
            setTimeoutId(null);
        }

        // If it's the last slide, set a timeout to go back to the first slide
        if (pageIndex === homeBanner?.sectionone?.length - 1) {
            const newTimeoutId = setTimeout(() => {
                if (carouselRef.current) {
                    carouselRef.current.goTo(0);
                }
            }, 3000); // Replace 7000 with your desired delay time in milliseconds

            setTimeoutId(newTimeoutId);
        }
    };

    return (
        <div className='banner pt-5'>
            <div className='bannerContent pb-1 '>
                <Carousel
                    className="carouselContainer"
                    breakPoints={breakPoint}
                    pagination={true}
                    enableAutoPlay={true}
                    autoPlaySpeed={7000}
                    isRTL={false}
                    enableSwipe={true}
                    disableArrowsOnEnd={true}
                    itemPosition="start"
                    focusOnSelect={false}
                    isInfinite={true}
                    onChange={handleSlideChange}
                    ref={carouselRef}
                >
                    {homeBanner?.sectionone?.map((slides, index) => (
                        <div key={index} className='container'>
                            <div className='contentContainer row'>
                                <div className='bannerText col-md-6'>
                                    <h1 style={{ color: 'white' }}>{slides?.heading}</h1>
                                    <p style={{ wordWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: slides?.description }}></p>
                                </div>
                                <div className='bannerImg col-md-6 mt-3 mb-1'>
                                    <img src={imag_url + slides?.image_top} className='img-fluid' style={{ height: "100%" }} alt={`Slide ${index}`} />
                                </div>
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
        </div>
    );
}

export default Banner;