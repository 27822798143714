import React, { useEffect } from 'react'
import Navbar from '../Components/Navbar'
import Banner from '../Components/Banner'
import Degital_Design from '../Components/Degital_Design'
import Development from '../Components/Development'
import Degital_Marketing from '../Components/Degital_Marketing'
import FAQ from '../Components/FAQ'
import Innovate from '../Components/Innovate'
import Footer from '../Components/Footer'
import AreYou from '../Components/AreYou'
import ClientSatsifaction from '../Components/ClientSatsifaction'
import Slider from '../Components/Slider'
import Limitation from '../Components/Limitation'
import Digital_marketing from './Digital_marketing'
import { useData } from '../Context/Context_Provider'
import { Helmet } from 'react-helmet'

function Home() {
  const { product, getProducts } = useData();

  useEffect(() => {
    getProducts();
  }, []);

  const title = product[0]?.meta_title || 'Default Title';
  const description = product[0]?.meta_description || 'Default Description';
  const tags = product[0]?.meta_tags || 'Default Tags';

  // Split the tags string into an array, assuming it's comma-separated
  const tagArray = tags.split(',').map((tag) => tag.trim());
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        {tagArray.map((tag, index) => (
          <meta name="keywords" content={tag} key={index} />
        ))}
      </Helmet>
      <Navbar />
      <Banner />
      <Limitation />
      <Degital_Design />
      <Development />
      <Degital_Marketing />
      <Slider />
      <AreYou />
      <ClientSatsifaction />
      <FAQ />
      <Innovate />
      <Footer />
    </>
  )
}

export default Home