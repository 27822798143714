import React, { useEffect, useState } from 'react';
import { useData } from '../Context/Context_Provider';

function FAQ() {
    const { faqData, getFaqData } = useData();
    const [openItemIndex, setOpenItemIndex] = useState(null);

    useEffect(() => {
        getFaqData();
    }, []);

    const handleItemClick = (index) => {
        setOpenItemIndex(openItemIndex === index ? null : index);
    };

    return (
        <div className='faq_container p-5'>
            <div className='container '>
                <div className='row mb-5'>
                    <div className='col-12 text-center'>
                        <h1 className='frequently'>Frequently Asked Questions</h1>
                    </div>
                </div>
                <div className='row'>
                    <div className="accordion" id="accordionExample">
                        {faqData?.filter(item => item.homepage === 1).map((items, i) => (
                            <div className={`accordion-item ${openItemIndex === i ? 'open' : ''}`} key={i}>
                                <h2 className="accordion-header" id={`heading${i}`}>
                                    <button className={`accordion-button pt-3 p-2 ${openItemIndex === i ? 'rotate' : ''}`} type="button" onClick={() => handleItemClick(i)} aria-expanded={openItemIndex === i ? 'true' : 'false'} aria-controls={`collapse${i}`} style={{ fontSize: "14px", fontWeight: "600", color: "white !important" }}>
                                        {items.question}
                                    </button>
                                </h2>
                                <div id={`collapse${i}`} className={`accordion-collapse ${openItemIndex === i ? 'show' : 'collapse'}`} aria-labelledby={`heading${i}`}>
                                    <div className="accordion-body p-2" style={{ display: openItemIndex === i ? 'block' : 'none' }}>
                                        <p style={{ wordWrap: "break-word", color: "white !important" }} dangerouslySetInnerHTML={{ __html: items.answer }}></p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FAQ;
