import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FaChevronUp, FaEnvelope, FaFacebookF, FaInstagram, FaLinkedin, FaLinkedinIn, FaPhone, FaTwitter } from 'react-icons/fa'
import { useData } from '../Context/Context_Provider'

function Footer() {
    const { imag_url, socialLinks, getSocialLinks, websiteSetting, getWebSettings, developmentData, getDevelopmentData, sendCommentData, comment,individualService, getIndividualService, } = useData()
    useEffect(() => {
        getSocialLinks()
        getWebSettings()
        getDevelopmentData()
        sendCommentData()
        getIndividualService()
    }, [])
    const handelbacktotop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }
    
    const [showServicesCount, setShowServicesCount] = useState(3); // Initially show 3 services

    const handleSeeMore = () => {
        // Show 3 more services when "See More" is clicked
        setShowServicesCount(showServicesCount + 3);
    };

    const handleSeeLess = () => {
        // Show only 3 services when "See Less" is clicked
        setShowServicesCount(3);
    }
    const navigate = useNavigate()
    const getSeviceName = (id, name) => {
      navigate(`/services/${name}/${id}`)
      getDevelopmentData(id)
      getIndividualService(id)
    }
  
    return (
        <>
            <div className='footer p-5'>
                <div className='container'>
                    <div className='row backtotop'>
                        <div>
                            <button style={{ border: "none", backgroundColor: "#0e78b9", padding: "12px 18px", borderRadius: "50%", color: "#fff", fontSize: "25px" }} onClick={handelbacktotop}><FaChevronUp /></button>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-3 col-md-3 col-sm-12 col-12 fSec'>
                            <h3 className='heading'>Quick Links </h3>
                            <ul>
                                <li><Link to={"/"} className='link text-white'>Home</Link></li>
                                <li><Link to={"/about_us"} className='link text-white'>About Us</Link></li>
                                <li><Link to={"/blog"} className='link text-white'>Blogs</Link></li>
                                <li><Link to={"/projects"} className='link text-white'>Projects</Link></li>
                                <li><Link to={"/contact_us"} className='link text-white'>Contact Us</Link></li>
                            </ul>
                        </div>

                        <div className='col-lg-3 col-md-3 col-sm-12 col-12 fSec'>
                            <h3 className='heading'>Services </h3>
                            <ul>
                                <li><Link to={"/design"} className="link text-white">Design</Link></li>
                                <li><Link to={"/digital_marketing"} className="link text-white">Digital Marketing</Link></li>
                                {developmentData?.slice(0, showServicesCount).map((service) => (
                                    <li key={service.id} onClick={() => getSeviceName(service.id, service.secone_head_one)}>
                                        <Link className="link text-white">
                                            {service?.secone_head_one}
                                        </Link>
                                    </li>
                                ))}
                                {showServicesCount < developmentData?.length && (
                                    <button className='btn' style={{ border: "none", padding: "0", backgroundColor: "transparent", color: "white" }} onClick={handleSeeMore}>See more</button>
                                )}
                                {showServicesCount > 3 && (
                                    <button className='btn' style={{ border: "none", padding: "0", backgroundColor: "transparent", color: "white" }} onClick={handleSeeLess}>See less</button>
                                )}
                            </ul>
                        </div>
                        <div className='col-lg-3 col-md-3 col-sm-12 col-12 fSec'>
                            <h3 className='heading'>Follow Us </h3>
                            <ul>
                                {socialLinks?.map((socialLinks) => {
                                    return (
                                        <li><Link to={socialLinks?.url} className='link text-white'> <span className='icon-circle'><img src={imag_url + socialLinks?.icon} /> </span><span>{socialLinks?.name}</span></Link></li>

                                    )
                                })}

                            </ul>
                        </div>
                        <div className='col-lg-3 col-md-3 col-sm-12 col-12 fSec'>
                            <h3 className='heading'>Keep in touch</h3>
                            <ul>
                                <li><Link to={"#"} className='link text-white'> <span className='phone_icon'><FaPhone /> </span><span>{websiteSetting?.websetting[0]?.contact_number}</span></Link></li>
                                <li><Link to={"#"} className='link text-white'> <span className='envelop_icon'><FaEnvelope /> </span><span>{websiteSetting?.websetting[0]?.contact_email}</span></Link></li>
                            </ul>
                            <div className='newsletter mt-4'>
                                <h5>Subscribe for exclusive updates</h5>
                                <div className='newsFrom'>
                                    <form style={{ display: "flex" }}>
                                        <input className='form-control' type='email' placeholder='Enter Your Email' />
                                        <button type='submit' className='formBtn'>Subscribe</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className='whiteline' />
                    <div className='row'>
                        <div className='col-12 text-center'>
                            <h6 style={{ fontSize: "14px" }}>
                                <span>{websiteSetting?.websetting[0]?.privacy} </span> <span> &copy;{websiteSetting?.websetting[0]?.copyrights}</span>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer