import React, { useEffect, useState } from 'react';
import { useData } from '../Context/Context_Provider';

function About_us_counter() {
    const { about, getAbout } = useData();
    const [counters, setCounters] = useState({ countOne: 0, countTwo: 0, countThree: 0, countFour: 0 });

    useEffect(() => {
        getAbout();
    }, []);

    useEffect(() => {
        if (about && about[0]) {
            const maxCounts = {
                countOne: parseInt(about[0].secthree_count_one) || 0,
                countTwo: parseInt(about[0].secthree_count_two) || 0,
                countThree: parseInt(about[0].secthree_count_three) || 0,
                countFour: parseInt(about[0].secthree_count_four) || 0
            };

         
            Object.keys(maxCounts).forEach(key => {
                let value = 0;
                const interval = setInterval(() => {
                    if (value < maxCounts[key]) {
                        value++;
                        setCounters((prev) => ({ ...prev, [key]: value }));
                    } else {
                        clearInterval(interval);
                    }
                }, 20); 
            });
        }
    }, [about]);
    return (
        <>
            <div className='about_counter'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-3'>
                            <div className='counter text-center'>
                                {/* Use counters.countOne instead of about[0]?.secthree_count_one */}
                                <h1>{counters.countOne}<span dangerouslySetInnerHTML={{ __html: about[0]?.secthree_countone_digit }}></span></h1>
                                <h6>{about[0]?.secthree_head_one}</h6>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='counter text-center'>
                                {/* Use counters.countTwo instead of about[0]?.secthree_count_two */}
                                <h1>{counters.countTwo} <span dangerouslySetInnerHTML={{ __html: about[0]?.secthree_counttwo_digit }}></span></h1>
                                <h6>{about[0]?.secthree_head_two}</h6>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='counter text-center'>
                                {/* Use counters.countThree instead of about[0]?.secthree_count_three */}
                                <h1>{counters.countThree} <span dangerouslySetInnerHTML={{ __html: about[0]?.secthree_countthree_digit }}></span></h1>
                                <h6>{about[0]?.secthree_head_three}</h6>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='counter text-center'>
                                {/* Use counters.countFour instead of about[0]?.secthree_count_four */}
                                <h1>{counters.countFour} <span>{about[0]?.secthree_countfour_digit}</span></h1>
                                <h6>{about[0]?.secthree_head_four}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
      )
}

export default About_us_counter