import React, { useEffect } from 'react'
import image from '../Images/Frame 28.png'
import image1 from '../Images/Group 2.png'
import icon1 from '../Images/strategy 1.png'
import icon2 from '../Images/idea.png'
import icon3 from '../Images/grow.png'
import { useData } from '../Context/Context_Provider'
function Limitation() {
    const { imag_url, getProducts, product } = useData()
    useEffect(() => {
        getProducts()
    }, [])
    const truncateText = (text, maxLength) => {
        if (text?.length <= maxLength) {
            return text;
        }
        return text?.slice(0, maxLength) + '...';
    };
    return (
        <div>
            <div className='limitation'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-6 mb-3'>
                            <div className='textSection'>
                                <div className='leftSide'>
                                    <div className='imge'>
                                        <img src={image} />
                                        <div className='text'>
                                            {product[0]?.secone_sub_head}
                                        </div>
                                    </div>
                                </div>
                                <div className='headingSection'>
                                    <h1>
                                        {product[0]?.secone_head_one}
                                    </h1>
                                    <h1>
                                        {product[0]?.secone_head_two}
                                    </h1>
                                    <h1>
                                        {product[0]?.secone_head_three}
                                    </h1>
                                </div>
                                <div className='ro'>
                                    <div>
                                        <img src={imag_url + product[0]?.secone_iconone} style={{ width: '70px' }} />
                                        <p> {product[0]?.secone_iconone_head} </p>
                                    </div>
                                    <div>
                                        <img src={imag_url + product[0]?.secone_icontwo} style={{ width: '70px' }} />
                                        <p> {product[0]?.secone_icontwo_head} </p>
                                    </div>
                                    <div>
                                        <img src={imag_url + product[0]?.secone_iconthree} style={{ width: '70px' }} />
                                        <p> {product[0]?.secone_iconthree_head} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 bottomRow'>
                            <div className='container'>
                                <div className='textSect'>
                                    {/* <img src={product[0]?.secone_image} width={"100%"} /> */}
                                    <img src={imag_url + product[0]?.secone_image} width={"100%"} style={{ opacity: "30%" }} />
                                    <div className='content'>
                                            <p dangerouslySetInnerHTML={{ __html: truncateText(product[0]?.secone_desc,930 )}}></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Limitation
